import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { SchedulePeopleSelectorDiv } from "./elements";
import SchedulePeopleScrollSelector from "../SchedulePeopleScrollSelector";

const SchedulePeopleSelector = ({
  title,
  selected,
  titleIsBig,
  handleSetTheme,
  theme,
}) => {
  const scheduleQuery = useStaticQuery(graphql`
    {
      images: allCraftPagesQafDefaultEntry(
        filter: { title: { eq: "Programme" } }
      ) {
        nodes {
          title
          sectionId
          sectionPageQAFContent {
            ... on Craft_sectionPageQAFContent_themes_BlockType {
              id
              themeImage {
                ... on Craft_qafAssets_Asset {
                  id
                  localFile {
                    childImageSharp {
                      fixed(
                        quality: 100
                        width: 680
                        height: 500
                        fit: OUTSIDE
                      ) {
                        src
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
              themeTitle
            }
          }
        }
      }
    }
  `);

  const venueImages = scheduleQuery.images.nodes[0];
  const menuItems = [
    {
      title: venueImages.sectionPageQAFContent?.length
        ? venueImages.sectionPageQAFContent[0].themeTitle
        : "All",
      image:
        venueImages.sectionPageQAFContent[0] &&
        venueImages.sectionPageQAFContent[0].themeImage[0] ? (
          <Img
            fixed={
              venueImages.sectionPageQAFContent[0].themeImage[0].localFile
                ?.childImageSharp.fixed
            }
          />
        ) : null,
    },
    {
      title: venueImages.sectionPageQAFContent?.length
        ? venueImages.sectionPageQAFContent[1].themeTitle
        : "Cyborg",
      image:
        venueImages.sectionPageQAFContent[1] &&
        venueImages.sectionPageQAFContent[1].themeImage[0] ? (
          <Img
            fixed={
              venueImages.sectionPageQAFContent[1].themeImage[0].localFile
                ?.childImageSharp.fixed
            }
          />
        ) : null,
    },
    {
      title: venueImages.sectionPageQAFContent?.length
        ? venueImages.sectionPageQAFContent[2].themeTitle
        : "Nature",
      image:
        venueImages.sectionPageQAFContent[2] &&
        venueImages.sectionPageQAFContent[2].themeImage[0] ? (
          <Img
            fixed={
              venueImages.sectionPageQAFContent[2].themeImage[0].localFile
                ?.childImageSharp.fixed
            }
          />
        ) : null,
    },
    {
      title: venueImages.sectionPageQAFContent?.length
        ? venueImages.sectionPageQAFContent[3].themeTitle
        : "Childhood",
      image:
        venueImages.sectionPageQAFContent[3] &&
        venueImages.sectionPageQAFContent[3].themeImage[0] ? (
          <Img
            fixed={
              venueImages.sectionPageQAFContent[3].themeImage[0].localFile
                ?.childImageSharp.fixed
            }
          />
        ) : null,
    },
  ];

  return (
    <SchedulePeopleSelectorDiv>
      <div>
        <SchedulePeopleScrollSelector
          title={title}
          theme={theme}
          menuItems={menuItems}
          selected={selected}
          titleIsBig={titleIsBig}
          handleSetTheme={handleSetTheme}
          // returnLink={(e) => {
          //   navigate(e);
          // }}
        />
      </div>
    </SchedulePeopleSelectorDiv>
  );
};

export default SchedulePeopleSelector;
