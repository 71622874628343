import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { NotFoundDiv } from "./elements";
import { H2SmallStrong, H3SmallMedium } from "../Typography";

const NotFoundEvent = ({}) => {
  const scheduleFrontPageQuery = useStaticQuery(graphql`
    {
      info: allCraftPagesQafDefaultEntry(filter: { title: { eq: "Programme" } }) {
        nodes {
          sectionPageQAFContent {
            ... on Craft_sectionPageQAFContent_textAndQuote_BlockType {
              id
              description
              titleTextQuote
            }
          }
        }
      }
    }
  `);

  const notFoundSection = scheduleFrontPageQuery?.info?.nodes[0]?.sectionPageQAFContent[4];

  return (
    <NotFoundDiv>
      <div>
        <H2SmallStrong>{notFoundSection?.titleTextQuote}</H2SmallStrong>
        <H3SmallMedium>{notFoundSection?.description}</H3SmallMedium>
      </div>
    </NotFoundDiv>
  );
};

export default NotFoundEvent;
