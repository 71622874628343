import React from "react";
import PropTypes from "prop-types";
import ScheduleCoreScrollSelector from "./../ScheduleCoreScrollSelector";
// import MiscSocialSmall from "./../icons/MiscSocialSmall";
import { SchedulePeopleScrollSelectorDiv } from "./elements";

const titles = ["All", "Cyborg", "Nature", "Childhood"];

const SchedulePeopleScrollSelector = ({
  title,
  menuItems,
  returnLink,
  selected,
  titleIsBig,
  handleSetTheme,
  theme,
}) => {
  return (
    <SchedulePeopleScrollSelectorDiv titleIsBig={titleIsBig}>
      <ScheduleCoreScrollSelector
        menuItems={menuItems}
        returnLink={returnLink}
        venue
        theme={theme}
        selectedMode={selected ? true : false}
        selection={titles.indexOf(selected)}
        titleIsBig={titleIsBig}
        handleSetTheme={handleSetTheme}
      />
      <div>
        <h1>{title}</h1>
      </div>
    </SchedulePeopleScrollSelectorDiv>
  );
};
export default SchedulePeopleScrollSelector;

SchedulePeopleScrollSelector.propTypes = {
  title: PropTypes.node,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.node,
      link: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  returnLink: PropTypes.func,
};
