import styled from "styled-components";

export const ScheduleDiv = styled.div`
  --headerHeight: 110px;
  --backgroundColor: ${(props) => (props.backgroundColor ? props.backgroundColor : "var(--black)")};
  margin-top: var(--headerHeight);
  background-color: var(--backgroundColor);
  @media (max-width: 767px) {
    padding: 25px 0 0;
    & h3 {
      text-align: left;
      /* font-size: 24px;
      letter-spacing: 0.24px; */
      font-size: 36px !important;
      line-height: 105%;
    }
    & h5 {
      display: none;
    }
    & > div:nth-child(2) {
      display: none;
    }
    & > div:nth-child(3) {
      width: 100%;
      margin-top: 0;
      & > div {
        width: 100%;
        margin-left: 0;
        & > div {
          padding: 0;
          /* width: calc(50% - 10px) !important; */
          width: 100% !important;
        }
      }
    }
  }
`;

export const ScheduleSelectionDiv = styled.div`
  // background-color: var(--white);
  padding-top: 25px;
  position: relative;
  z-index: 1;
  &:before {
    position: absolute;
    left: -100vw;
    right: -100vw;
    top: 0;
    bottom: 0;
    content: "";
    // background-color: var(--white);
    z-index: 0;
    mix-blend-mode: darken;
  }
  & h3 {
    margin: 25px 100px;
    z-index: 2;
  }
  & > div {
    z-index: 2;
  }
  @media (max-width: 767px) {
    padding-top: 0;
  }
`;

export const EventDiv = styled.div`
  width: 100%;
  padding: ${(props) => (props.isScheduleList ? "50px var(--outerMargin) 30px" : "50px var(--outerMargin) 50px")};
  margin: 0 auto;
  background-color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    align-self: flex-start;
    color: var(--white);
    margin: 5px 0 50px 0;
    font-size: 42px;
    font-weight: 600;
    & > span {
      margin-right: 25px;
    }
  }
  @media (max-width: 767px) {
    padding: 36px 20px;
    & > p {
      margin: 0 0 36px;
      font-size: 28px;
      font-weight: 600;
    }
  }
`;
