import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { H2, H4BigStrong } from "../Typography";
import { ScrollSelectorDiv } from "./elements";

const CoreScrollSelector = ({
  menuItems,
  returnLink,
  selectedMode,
  selection,
  venue,
  titleIsBig,
  handleSetTheme,
  theme,
}) => {
  const [selected, setSelected] = useState(selectedMode ? selection : 0);

  useEffect(() => {
    switch (theme) {
      case "cyborg":
        setSelected(1);
        break;
      case "nature":
        setSelected(2);
        break;
      case "childhood":
        setSelected(3);
        break;
      default:
        setSelected(0);
    }
  }, [theme]);

  const handleChooseTheme = (index) => {
    let type = "";
    switch (index) {
      case 1:
        type = "cyborg";
        break;
      case 2:
        type = "nature";
        break;
      case 3:
        type = "childhood";
        break;
      default:
        type = "";
    }
    localStorage.setItem("theme", type);
    handleSetTheme(type);
  };

  return (
    <ScrollSelectorDiv
      venue={venue}
      selectedMode={selectedMode}
      className={titleIsBig ? "titleIsBig" : ""}
    >
      <ul>
        {menuItems.map((menuItem, index) => (
          <li
            key={index}
            className={
              selected === index ? `selected item-${index}` : `item-${index}`
            }
            onClick={() => {
              if (!selectedMode) {
                setSelected(index);
              }
              handleChooseTheme(index);
              // returnLink(menuItem.link);
            }}
          >
            <div>{menuItem.image ? menuItem.image : null}</div>
            {selected === index ? (
              <H2>{menuItem.title}</H2>
            ) : (
              <H4BigStrong>{menuItem.title}</H4BigStrong>
            )}
          </li>
        ))}
      </ul>
    </ScrollSelectorDiv>
  );
};

export default CoreScrollSelector;

CoreScrollSelector.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.node,
      link: PropTypes.string,
      title: PropTypes.string,
      selected: PropTypes.bool,
    })
  ),
  returnLink: PropTypes.func,
  selectedMode: PropTypes.bool,
};
