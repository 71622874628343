import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import { ScheduleCoreFilterDiv, FilterItem } from "./elements";

const ScheduleCoreFilter = ({ filterList, selected, backgroundColorOfSelected, useColor, modal, selectedTypes, setSelectedTypes }) => {
  const addType = (myType) => {
    const newTypes = modal ? [myType] : [myType, ...selectedTypes];
    setSelectedTypes(newTypes);
    selected(newTypes);
  };

  const removeType = (myType) => {
    const newTypes = selectedTypes.filter((x) => x !== myType);
    setSelectedTypes(newTypes);
    selected(newTypes);
  };

  return (
    <ScheduleCoreFilterDiv>
      <div>
        {filterList.map((type, index) => (
          <FilterItem
            href="/#"
            key={index}
            className={selectedTypes.indexOf(type.id) > -1 ? "selected" : ""}
            selectedColor={useColor && backgroundColorOfSelected ? backgroundColorOfSelected : null}
            onClick={(e) => {
              e.preventDefault();
              selectedTypes.indexOf(type.id) > -1 ? removeType(type.id) : addType(type.id);
            }}
          >
            {type.name}
          </FilterItem>
        ))}
      </div>
    </ScheduleCoreFilterDiv>
  );
};

export default ScheduleCoreFilter;

ScheduleCoreFilter.propTypes = {
  /** This is a list of names and values for the filter. `id` gets passed back */
  filterList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  /** This is the function that passes back a list of values as defined in `filterList` */
  selected: PropTypes.func,
  /** If this is set to `true`, a time selector is shown */
  isEvents: PropTypes.bool,
  /** This is the function that passes back the selected time value, if `isEvents` is `true` */
  timeSelected: PropTypes.func,
  /**background corresponds to the theme */
  backgroundColorOfSelected: PropTypes.string,
  /** This is a list of selectedTypes */
  selectedTypes: PropTypes.any,
  /** This is the function that setSelectedTypes */
  setSelectedTypes: PropTypes.func,
};

ScheduleCoreFilter.defaultProps = {
  isEvents: false,
  timeSelected: () => null,
};
