import styled from "styled-components";

export const ScheduleCoreFilterDiv = styled.div`
  display: flex;
  width: 100%;
  max-width: var(--width);
  padding-left: var(--outerMargin);
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  & strong {
    font-weight: 700 !important;
  }
  margin-top: 8px;
  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    justify-content: flex-start;
    & strong {
      margin-right: 8px;
      padding: 5px 0;
      font-size: 12px;
      letter-spacing: 0.24px;
      font-weight: bold;
      user-select: none;
      color: var(--white);
    }
    & + a {
      /* --clearColor: #cfcecb; */
      color: var(--mid);
      text-decoration: underline;
      font-size: 12px;
      padding: 5px 0;
      letter-spacing: 0.03em;
      font-weight: normal;
      white-space: nowrap;
      user-select: none;
      margin-left: 8px;
      transition: 0.5s;
      &.disabled {
        color: var(--mid);
      }
    }
  }
  & .react-dropdown-select {
    width: 135px;
    background-color: var(--light);
    color: var(--black);
    margin-right: 15px;
    height: 28px;
    font-size: 12px;
    min-height: 25px;
    outline: none;
    appearance: none;
    border: none;
    & input {
      font-family: var(--headerFont);
      font-size: 12px;
      color: var(--black);
      font-weight: 500;
    }
    &:active,
    &:focus {
      outline: none;
      appearance: none;
      border: none;
      box-shadow: none;
    }
    &:focus-within {
      border: none;
    }
    & .react-dropdown-select-dropdown {
      top: 25px;
      border: none;
      left: 0;
    }
    & .react-dropdown-select-item {
      border-bottom: none;
      background-color: var(--slate);
      color: var(--white);
      height: 25px;
      font-weight: 500;
    }
  }
  @media (min-width: 1441px) {
    /* max-width: initial; */
    padding-right: 0;
  }
  @media (max-width: 767px) {
    margin-top: 0;
    padding: 0;
    flex-direction: column;
    align-items: flex-end;
    & > div {
      flex-direction: row !important;
      & a {
        margin: 4px 4px;
      }
    }
  }
`;

export const FilterItem = styled.a`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.24;
  padding: 5px 12px;
  background-blend-mode: multiply;
  background-color: var(--slate);
  color: var(--white);
  transition: 0.5s;
  white-space: nowrap;
  user-select: none;
  &.selected {
    background-color: ${(props) =>
      props.selectedColor ? props.selectedColor : "var(--red)"};
    color: ${(props) =>
      props.selectedColor ? "var(--black)" : "var(--white)"};
  }
  margin: 4px 8px 4px 0;
  /* & + a {
    margin-left: 8px;
  } */
`;
