import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import { ScheduleSectionFilterDiv, FilterItem } from "./elements";
import ScheduleCoreFilter from "../ScheduleCoreFilter";

const ScheduleSectionFilter = ({
  filterList,
  selected,
  handleSetFilterType,
  backgroundColorOfSelected,
  useColor,
  modal,
  theme,
}) => {
  const dateTypes = [
    { id: "28 January", name: "28 January" },
    { id: "29 January", name: "29 January" },
  ];

  const timeTypes = [
    { id: "morning", name: "Morning" },
    { id: "afternoon", name: "Afternoon" },
    { id: "evening", name: "Evening" },
  ];

  const eventTypesNoTheme = [
    { id: "exhibitions", name: "Exhibitions" },
    { id: "talks", name: "Talks" },
    { id: "films", name: "Films" },
    { id: "theatreDance", name: "Theatre & Dance" },
    { id: "music", name: "Music" },
    { id: "kids", name: "Kids" },
    { id: "foodDrink", name: "Food & Drink" },
    { id: "tours", name: "Tours" },
    { id: "workshops", name: "Workshops" },
    { id: "fitnessWellness", name: "Fitness & Wellness" },
    { id: "guestProjects", name: "Guest Projects" },
    { id: "commissions", name: "Commissions" },
    { id: "performance", name: "Performance" },
    { id: "grants", name: "Grants" },
  ];

  const eventTypes = [
    { id: "exhibitions", name: "Exhibitions" },
    { id: "talks", name: "Talks" },
    { id: "films", name: "Films" },
    { id: "theatreDance", name: "Theatre & Dance" },
    { id: "music", name: "Music" },
    { id: "kids", name: "Kids" },
    { id: "tours", name: "Tours" },
    { id: "workshops", name: "Workshops" },
    { id: "fitnessWellness", name: "Fitness & Wellness" },
    { id: "guestProjects", name: "Guest Projects" },
    { id: "commissions", name: "Commissions" },
    { id: "performance", name: "Performance" },
    { id: "grants", name: "Grants" },
  ];

  const [selectedTypes, setSelectedTypes] = useState([]);
  const [listTypes, setListTypes] = useState([]);
  const [isOpen, setIsOpen] = useState([false, false, false, false]);
  const [itemSelectedTypes, setItemSelectedTypes] = useState([]);
  const [scheduleListType, setScheduleListType] = useState([
    dateTypes,
    timeTypes,
    eventTypesNoTheme,
  ]);

  useEffect(() => {
    if (theme) {
      setScheduleListType([dateTypes, timeTypes, eventTypes]);
      if (selectedTypes[0] === "event") {
        setListTypes(eventTypes);
      }
    } else {
      setScheduleListType([dateTypes, timeTypes, eventTypesNoTheme]);
      if (selectedTypes[0] === "event") {
        setListTypes(eventTypesNoTheme);
      }
    }
  }, [theme]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      if (queryString) {
        const urlParams = new URLSearchParams(queryString);
        const type = urlParams.get("type");
        handleSetFilterType("event");
        handleChangeSection(null, { id: "event", name: "Event" }, 2);
        setItemSelectedTypes([type]);
        selected([type]);
      }
    }
  }, []);

  const addType = (myType) => {
    const newTypes = modal ? [myType] : [myType, ...selectedTypes];
    setSelectedTypes(newTypes);
  };

  const removeType = (myType) => {
    const newTypes = selectedTypes.filter((x) => x !== myType);
    setSelectedTypes(newTypes);
  };

  const handleChangeSection = (e, type, sectionIndex) => {
    if (e) {
      e.preventDefault();
    }
    const newIsOpen = [];
    isOpen.map((item, itemIndex) => {
      if (sectionIndex === itemIndex) {
        newIsOpen.push(!item);
        if (!item) {
          setListTypes(scheduleListType[itemIndex]);
        }
      } else {
        newIsOpen.push(false);
      }
    });
    setIsOpen(newIsOpen);
    selectedTypes.indexOf(type.id) > -1
      ? removeType(type.id)
      : addType(type.id);
  };

  return (
    <ScheduleSectionFilterDiv>
      <div>
        <div>
          <strong>Filter by</strong>

          {filterList.map((type, index) => (
            <Fragment key={index}>
              <FilterItem
                href="/#"
                className={
                  selectedTypes.indexOf(type.id) > -1 ? "selected" : ""
                }
                selectedColor={
                  useColor && backgroundColorOfSelected
                    ? backgroundColorOfSelected
                    : null
                }
                onClick={(e) => {
                  handleSetFilterType(type?.id);
                  handleChangeSection(e, type, index);
                }}
              >
                {type.name}
                <span className={isOpen[index] ? "open" : "closed"}>
                  <svg fill="currentColor" viewBox="0 0 40 40">
                    <path d="M31 26.4q0 .3-.2.5l-1.1 1.2q-.3.2-.6.2t-.5-.2l-8.7-8.8-8.8 8.8q-.2.2-.5.2t-.5-.2l-1.2-1.2q-.2-.2-.2-.5t.2-.5l10.4-10.4q.3-.2.6-.2t.5.2l10.4 10.4q.2.2.2.5z"></path>
                  </svg>
                </span>
              </FilterItem>
              {isOpen[index] && (
                <div className="filter--mobile">
                  <ScheduleCoreFilter
                    selected={selected}
                    filterList={listTypes}
                    backgroundColorOfSelected={backgroundColorOfSelected}
                    useColor
                    modal
                    selectedTypes={itemSelectedTypes}
                    setSelectedTypes={setItemSelectedTypes}
                  />
                </div>
              )}
            </Fragment>
          ))}
        </div>
        <a
          id="clear-filter"
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            setSelectedTypes([]);
            selected([]);
            setItemSelectedTypes([]);
            setIsOpen([false, false, false, false]);
          }}
          className={selectedTypes.length ? "" : "disabled"}
        >
          Clear Selection
        </a>
      </div>

      {/* <div> */}
      {isOpen.includes(true) && (
        <div className="filter--desktop">
          <ScheduleCoreFilter
            selected={selected}
            filterList={listTypes}
            backgroundColorOfSelected={backgroundColorOfSelected}
            useColor
            modal
            selectedTypes={itemSelectedTypes}
            setSelectedTypes={setItemSelectedTypes}
          />
        </div>
      )}
      {/* </div> */}
    </ScheduleSectionFilterDiv>
  );
};

export default ScheduleSectionFilter;

ScheduleSectionFilter.propTypes = {
  /** This is a list of names and values for the filter. `id` gets passed back */
  filterList: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
  ),
  /** This is the function that passes back a list of values as defined in `filterList` */
  selected: PropTypes.func,
  /** This is the function in order to set type of filter */
  handleSetFilterType: PropTypes.func,
  /**background corresponds to the theme */
  backgroundColorOfSelected: PropTypes.string,
  /**this is the theme */
  theme: PropTypes.string,
};
