import styled from "styled-components";

export const ScheduleMapToggleElement = styled.ul`
  --backgroundColor: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--green)"};
  list-style-type: none;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin: 25px 0;
  padding: 0;
  @media (max-width: 767px) {
    margin: 36px 0;
  }
  & li {
    display: inline-flex;
    width: 69px;
    height: 25px;
    background-color: var(--dark);
    font-size: 12px;
    letter-spacing: 0.24px;
    & a {
      color: var(--white);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    &.selected {
      background-color: var(--backgroundColor);
      & a {
        color: var(--black);
      }
    }
    & + li {
      margin-left: 3px;
    }
  }
`;
