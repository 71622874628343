import styled from "styled-components";

export const SchedulePeopleSelectorDiv = styled.div`
  // background-color: var(--white);
  display: flex;
  justify-content: center;
  & > div:first-child {
    // max-width: var(--width);
    /* padding-top: 50px; */
    & h1 {
      color: var(--purple);
    }
  }
  @media (max-width: 1439px) {
    & > div:first-child h1 {
      margin-top: 29px;
      white-space: nowrap;
      font-size: 120px;
      line-height: 120px;
    }
  }
  @media (max-width: 1023px) {
    --outerMargin: 50px;
    & > div > div > div:first-of-type + div {
      padding-left: 0;
    }
    & > div:first-child h1 {
      margin-top: 54px;
      white-space: nowrap;
      font-size: 80px;
      line-height: 100px;
      margin-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    --outerMargin: 0;
    & > div:first-child {
      width: 100%;
      padding-top: 0;
      & > div {
        & > div {
          &:first-of-type.titleIsBig {
            top: 240px;
          }
          & + div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            & h1 {
              margin-top: 25px !important;
              text-align: center;
              padding: 0 10px;
              max-width: 100%;
              white-space: initial;
              font-size: 42px;
              line-height: 48px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
`;
