import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import ScheduleFilter from "../../components/ScheduleFilter";
import ScheduleHero from "../../components/ScheduleHero";
import ScheduleMapToggle from "../../components/ScheduleMapToggle";
import SchedulePeopleSelector from "../../components/SchedulePeopleSelector";
import {
  EventDiv,
  ScheduleDiv,
  ScheduleSelectionDiv,
} from "../../components/ScheduleStyles/elements";
import { BigButtonSchedule } from "../../components/ButtonsSchedule";
import { getDate, getTimeHaveSpace } from "../../modules/time";
import EventList from "../../components/EventList";
import NotFoundEvent from "../../components/NotFoundEvent";

const ScheduleListPage = () => {
  const scheduleQuery = useStaticQuery(graphql`
    {
      events: allCraftQafEventsQafEventEntry {
        nodes {
          title
          slug
          subtitle
          eventShortDescription
          headerImage: qafEventHeaderImage {
            ... on Craft_eventsAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(width: 4000, fit: COVER, quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          eventType
          eventStartDateTime
          eventEndDateTime
          qafEventSubTheme
          qafEventLocation {
            title
            ... on Craft_participants_default_Entry {
              id
              participantLocation {
                id
                title
              }
              communityMemberOpeningHours
              communityMemberSlug
              communityMemberWebsite
              communityMemberEmailAddress
              communityMemberDescription
              communityMemberContactDetails {
                col1
                col2
              }
              participantType
            }
            ... on Craft_maplocation_default_Entry {
              locationId
              locationName
              title
              allowClick
            }
          }
        }
      }
    }
  `);

  const now = new Date();
  const availableEvents = scheduleQuery?.events?.nodes?.filter((item) => {
    const thisDate = new Date(
      item.eventEndDateTime || item.eventStartDateTime || null
    );
    return thisDate >= now;
  });

  const [end, setEnd] = useState(12);
  const [eventList, setEventList] = useState([]);
  const [currentTypes, setCurrentTypes] = useState([]);
  const [currentFilterType, setCurrentFilterType] = useState("");
  const [changedTypes, setChangedTypes] = useState(false);
  const [theme, setTheme] = useState("");
  const [layoutBgColor, setLayoutBgColor] = useState("var(--black)");
  const [bgMapToggle, setBgMapToggle] = useState("var(--green)");

  const handleSetTheme = (type) => {
    if (currentTypes[0] === "foodDrink") {
      setCurrentTypes([]);
    }
    document.getElementById("clear-filter")?.click();
    setTheme(type);
  };

  const handleSetFilterType = (type) => {
    setCurrentFilterType(type);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setTheme(localStorage.getItem("theme"));
    }
  }, []);

  useEffect(() => {
    if (currentTypes.length > 0) {
      if (currentFilterType === "date") {
        const dateFilter = new Date(
          getDate(`${currentTypes[0]} 2023`)
        ).getTime();
        setEventList(
          availableEvents
            ?.filter((item) =>
              theme ? item.qafEventSubTheme[0] === theme : item
            )
            .filter((eventItem) => {
              const startDate = new Date(
                getDate(eventItem?.eventStartDateTime)
              ).getTime();
              const endDate = new Date(
                getDate(eventItem?.eventEndDateTime)
              ).getTime();
              if (startDate <= dateFilter && dateFilter <= endDate) return true;
              return false;
            })
        );
      } else if (currentFilterType === "time") {
        let morningEvents = [];
        let afternoonEvents = [];
        let eveningEvents = [];
        availableEvents
          ?.filter((item) =>
            theme ? item.qafEventSubTheme[0] === theme : item
          )
          .map((eventItem) => {
            const startDate = getTimeHaveSpace(
              eventItem?.eventStartDateTime
            ).split(" ");
            let timeOfStartDate = [];
            if (startDate[0].includes(":")) {
              timeOfStartDate = startDate[0].split(":");
            } else {
              timeOfStartDate = startDate[0].split(".");
            }
            let hourOfStartDate = Number(timeOfStartDate[0]);
            const minuteOfStartDate = Number(timeOfStartDate[1]);
            const suffixesOfStartDate = startDate[1];
            if (hourOfStartDate === 12) hourOfStartDate = 0;
            let startTime = minuteOfStartDate + hourOfStartDate * 60;
            if (suffixesOfStartDate === "pm") {
              startTime += 720;
            }

            const endDate = getTimeHaveSpace(eventItem?.eventEndDateTime).split(
              " "
            );
            let timeOfEndDate = [];
            if (endDate[0].includes(":")) {
              timeOfEndDate = endDate[0].split(":");
            } else {
              timeOfEndDate = endDate[0].split(".");
            }
            let hourOfEndDate = Number(timeOfEndDate[0]);
            const minuteOfEndDate = Number(timeOfEndDate[1]);
            const suffixesOfEndDate = endDate[1];
            if (hourOfEndDate === 12) hourOfEndDate = 0;
            let endTime = minuteOfEndDate + hourOfEndDate * 60;
            if (suffixesOfEndDate === "pm") {
              endTime += 720;
            }

            if (startTime < 780) {
              morningEvents.push(eventItem);
            }
            if (startTime < 1080 && endTime >= 780) {
              afternoonEvents.push(eventItem);
            }
            if (endTime >= 1081) {
              eveningEvents.push(eventItem);
            }
          });
        switch (currentTypes[0]) {
          case "morning":
            setEventList(morningEvents);
            break;
          case "afternoon":
            setEventList(afternoonEvents);
            break;
          case "evening":
            setEventList(eveningEvents);
            break;
        }
      } else if (currentFilterType === "event") {
        setEventList(
          availableEvents
            ?.filter((item) =>
              theme ? item.qafEventSubTheme[0] === theme : item
            )
            .filter((eventItem) =>
              eventItem?.eventType?.includes(currentTypes[0])
            )
        );
      }
    } else {
      setEventList(availableEvents);
    }
  }, [currentTypes]);

  useEffect(() => {
    handleTheme();
  }, [theme]);

  const handleClickLoadMore = () => {
    setEnd((pre) => pre + 12);
  };

  const handleTheme = () => {
    let _layoutBgColor = "";
    let _bgMapToggle = "";
    switch (theme) {
      case "cyborg":
        _layoutBgColor = "var(--red)";
        _bgMapToggle = "var(--blue)";
        setEventList(
          availableEvents
            ?.filter((item) => item.qafEventSubTheme[0] === "cyborg")
            .filter((item) =>
              currentTypes?.length
                ? item?.eventType?.includes(currentTypes[0])
                : item
            )
        );
        break;
      case "nature":
        _layoutBgColor = "var(--yellow2)";
        _bgMapToggle = "var(--green2)";
        setEventList(
          availableEvents
            ?.filter((item) => item.qafEventSubTheme[0] === "nature")
            .filter((item) =>
              currentTypes?.length
                ? item?.eventType?.includes(currentTypes[0])
                : item
            )
        );
        break;
      case "childhood":
        _layoutBgColor = "var(--purple)";
        _bgMapToggle = "var(--pink)";
        setEventList(
          availableEvents
            ?.filter((item) => item.qafEventSubTheme[0] === "childhood")
            .filter((item) =>
              currentTypes?.length
                ? item?.eventType?.includes(currentTypes[0])
                : item
            )
        );
        break;
      default:
        _layoutBgColor = "var(--black)";
        _bgMapToggle = "var(--green)";
        setEventList(
          availableEvents.filter((item) =>
            currentTypes?.length
              ? item?.eventType?.includes(currentTypes[0])
              : item
          )
        );
    }
    setLayoutBgColor(_layoutBgColor);
    setBgMapToggle(_bgMapToggle);
  };

  return (
    <Layout backgroundColor={layoutBgColor} skipHeader headerBlack={true}>
      <ScheduleDiv backgroundColor={layoutBgColor}>
        <ScheduleHero topLine="Programme" />
        <ScheduleMapToggle backgroundColorOfSelected={bgMapToggle} />
        <ScheduleSelectionDiv>
          <SchedulePeopleSelector
            handleSetTheme={handleSetTheme}
            theme={theme}
          />
        </ScheduleSelectionDiv>
      </ScheduleDiv>
      <ScheduleFilter
        theme={theme}
        selected={(e) => {
          setCurrentTypes(e);
          setChangedTypes(() => !changedTypes);
        }}
        handleSetFilterType={handleSetFilterType}
        backgroundColorOfSelected={bgMapToggle}
      />

      <div>
        <EventDiv isScheduleList>
          <EventList
            events={
              theme
                ? eventList
                    ?.filter((item) => !item.eventType?.includes("foodDrink"))
                    .slice(0, end)
                : eventList?.slice(0, end)
            }
            theme={theme}
          />
          {eventList.length === 0 ? (
            <NotFoundEvent />
          ) : (
            theme &&
            eventList?.filter((item) => !item.eventType?.includes("foodDrink"))
              .length === 0 && <NotFoundEvent />
          )}
          {theme
            ? eventList?.filter(
                (item) => !item.eventType?.includes("foodDrink")
              ).length > end && (
                <BigButtonSchedule
                  backgroundColor="var(--green)"
                  color="var(--black) "
                  onClick={handleClickLoadMore}
                >
                  Load more
                </BigButtonSchedule>
              )
            : eventList?.length > end && (
                <BigButtonSchedule
                  backgroundColor="var(--green)"
                  color="var(--black) "
                  onClick={handleClickLoadMore}
                >
                  Load more
                </BigButtonSchedule>
              )}
        </EventDiv>
      </div>
    </Layout>
  );
};

export default ScheduleListPage;
