import React from "react";
import PropTypes from "prop-types";
import ScheduleSectionFilter from "../ScheduleSectionFilter";

const ScheduleFilter = ({ selected, handleSetFilterType, backgroundColorOfSelected, theme }) => {
  const scheduleTypes = [
    { id: "date", name: "Date" },
    { id: "time", name: "Time" },
    { id: "event", name: "Event" },
  ];

  return (
    <ScheduleSectionFilter
      theme={theme}
      selected={selected}
      handleSetFilterType={handleSetFilterType}
      backgroundColorOfSelected={backgroundColorOfSelected}
      filterList={scheduleTypes}
      useColor
      modal
    />
  );
};

export default ScheduleFilter;

ScheduleFilter.propTypes = {
  selected: PropTypes.func,
  handleSetFilterType: PropTypes.func,
  backgroundColorOfSelected: PropTypes.string,
  theme: PropTypes.string,
};
