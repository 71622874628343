import React from "react";
import PropTypes from "prop-types";
import { H5SmallMedium } from "./../Typography";
import { ScheduleHeroDiv } from "./elements";

const ScheduleHero = ({ topLine, color }) => (
  <ScheduleHeroDiv color={color}>
    <H5SmallMedium>{topLine}</H5SmallMedium>
  </ScheduleHeroDiv>
);

export default ScheduleHero;

ScheduleHero.propTypes = {
  topLine: PropTypes.string,
};

ScheduleHero.defaultProps = {
  topLine: "Discover the Avenue",
};
