import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { ScheduleMapToggleElement } from "./elements.js";

const ScheduleMapToggle = ({ isMap, backgroundColorOfSelected }) => {
  return (
    <ScheduleMapToggleElement backgroundColor={backgroundColorOfSelected}>
      <li className={isMap ? "" : "selected"}>
        <Link
          to={`/programme`}
          onClick={() => {
            if (typeof window !== "undefined") {
              localStorage.removeItem("theme");
            }
          }}
        >
          List
        </Link>
      </li>
      <li className={isMap ? "selected" : ""}>
        <Link to={`/programme/map`}>Map</Link>
      </li>
    </ScheduleMapToggleElement>
  );
};

export default ScheduleMapToggle;

ScheduleMapToggle.propTypes = {
  backgroundColorOfSelected: PropTypes.string,
};

ScheduleMapToggle.defaultProps = {
  backgroundColorOfSelected: "var(--green)",
};
